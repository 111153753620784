import { DEFAULT_LANG } from './lang';

interface GroupByMonthAndYearArgs<T> {
  data?: Array<T>;
  dateField: string;
  locale?: string;
}

export const calculateTimeUntil = (datetime: string) => {
  const targetDate = new Date(datetime).getTime();
  const now = new Date().getTime();
  const difference = targetDate - now;

  if (difference <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((difference / (1000 * 60)) % 60);
  const seconds = Math.floor((difference / 1000) % 60);

  return { days, hours, minutes, seconds };
};

export const padStart = (number: number, length: number = 2, fillString: string = '0') =>
  number.toString().padStart(length, fillString);

export const groupByMonthAndYear = <T>({
  data,
  dateField,
  locale
}: GroupByMonthAndYearArgs<T>): Array<{ label: string; items: T[] }> => {
  if (!data?.length) return [];

  const getMonthYear = date => {
    return new Date(date).toLocaleDateString(locale, { year: 'numeric', month: 'long' });
  };

  // Group the objects by "Month Year"
  const grouped = data.reduce((acc, item) => {
    const monthYear = getMonthYear(item[dateField]);
    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }
    acc[monthYear].push(item);
    return acc;
  }, {});

  // Sort the keys (month year) in descending order
  const sortedKeys = Object.keys(grouped).sort(
    (a, b) => new Date(b).getMilliseconds() - new Date(a).getMilliseconds()
  );

  // Rebuild the object in sorted order as an array
  const sortedGrouped = sortedKeys.map(key => ({
    label: key,
    items: grouped[key]
  }));

  return sortedGrouped;
};

// Function to calculate how many seconds until midnight
export const getSecondsUntilMidnight = () => {
  const now = new Date();
  const midnight = new Date();
  midnight.setHours(24, 0, 0, 0); // Set midnight of the current day
  return (midnight.getTime() - now.getTime()) / 1000; // Difference in seconds
};

export const getSecondsUntil = (date: Date) => {
  const now = new Date();
  return (date.getTime() - now.getTime()) / 1000; // Difference in seconds
};
