import { useLoyalty } from 'contexts/loyalty';

export const useGetUserProvisionalBalance = () => {
  const { userBalance } = useLoyalty();
  const { swisscardProvisionalBalances } = userBalance || {};

  if (swisscardProvisionalBalances) {
    return swisscardProvisionalBalances.reduce((acc: number, curr) => {
      const { provisionalBalance: pb } = curr || {};
      return Math.max(0, pb) + acc;
    }, 0);
  }
  return 0;
};

export const useGetBlockedBalance = () => {
  const { userBalance } = useLoyalty();
  const provisionalBalance = useGetUserProvisionalBalance();
  return (userBalance?.blockedBalance || 0) + provisionalBalance;
};

export const useHasCreditCard = () => {
  const { userBalance } = useLoyalty();

  const { swisscardProvisionalBalances } = userBalance || {};
  return Array.isArray(swisscardProvisionalBalances) && swisscardProvisionalBalances.length > 0;
};

export const useGetTotalBalance = () => {
  const { userBalance } = useLoyalty();
  const { balance } = userBalance || {};
  const blockedBalance = useGetBlockedBalance();
  return (balance || 0) + blockedBalance;
};
